$(document).ready(function() {
    dropNav();
    navOpen();
    navItemCollapse();
    expertise();
    accordianJobs();
    contactform();


});

// $(window).on("load", function(){

//     smoothScroll();
// });

$(window).on("load", function() {
    var urlHash = window.location.href.split("#")[1];
    if (urlHash && $('#' + urlHash).length)
        $('html,body').animate({
            scrollTop: $('#' + urlHash).offset().top - 92
        }, 400);
});

$(window).resize(function() {
    dropNav();
    // navItemCollapse();
});



var dropNav = function() {
    var windowWidth = $(window).width();

    var navWidth = $('.nav-bar').width();

    if (windowWidth > 1200) {
        // multiDropMenu.width(navWidth);
        $('multi-drop-wrapper .drop-menu-inner').width(navWidth);
    } else {
        $('multi-drop-wrapper .drop-menu-inner').width(100 + "%");
    }

}

var navOpen = function() {
    $('.menu-icon').on("click", function() {
        $('.nav-inner-js').toggleClass('nav-open');
    });
}

var navItemCollapse = function() {
    var windowWidth = $(window).width();
    if (windowWidth < 900) {
        $('.main-nav li.multi-drop-wrapper > a, .main-nav li.drop-down-nav-item > a').removeAttr('href');
        $('.drop-menu.multi-col-drop-menu, .drop-menu.single-drop-menu, .multi-col-drop-menu .drop-menu-list').hide();

        $('.drop-menu-list-header').on("click", function() {
            $(this).siblings('.drop-menu-list').slideToggle();
        });

        $('.nav-main-level-js').on("click", function() {
            $(this).siblings('.drop-menu.multi-col-drop-menu, .drop-menu.single-drop-menu').slideToggle();
        });

        $('.drop-menu-items a').on("click", function() {
            $('.nav-inner-js').removeClass('nav-open');
        })
    }
}

var expertise = function() {
    $('.expertice-line-wrapper').slick({
        slidesToShow: 8,
        slidesToScroll: 3,
        autoplay: true,
        Infinity: true,
        autoplaySpeed: 2000,
    });

    $('.banner-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        pauseOnFocus: false,
        autoplay: true,
        Infinity: true,
        autoplaySpeed: 2000,
    });
}

var smoothScroll = function() {
    // $('a[href*="#"]:not([href="#"])').click(function() {
    //     var target = $(this.hash);
    //     $('html,body').stop().animate({
    //         scrollTop: target.offset().top - 100
    //     }, 1100);
    // });
    // if (location.hash) {
    //     var id = $(location.hash);
    // }
    // $(window).load(function() {
    //     if (location.hash) {
    //         $('html,body').animate({ scrollTop: id.offset().top - 100 }, 600)
    //     };
    // });

    // $("a").on('click', function(event) {

    //     if (this.hash !== "") {
    //       //event.preventDefault();

    //       var hash = window.location.hash;

    //       $('html, body').animate({
    //         scrollTop: $(hash).offset().top-92
    //       }, 800, function(){

    //         window.location.hash = hash;
    //       });
    //     } 
    //   });
}

var accordianJobs = function() {
    var jobTitle = $('.job-title');
    var jobDesc = $('.job-description');
    var firstJob = $('.job').first().children('.job-description');



    //jobDesc.not(firstJob).hide();
    jobDesc.hide();

    jobTitle.on("click", function() {
        $(this).siblings('.job-description').slideToggle();
        $(this).parents('.job').toggleClass('open') && $(this).parents('.job').siblings().removeClass('open');
        $(this).parents('.job').siblings().find('.job-description').hide();
    });
}

// function sendMail() {
//     Email.send({
//         Host: "smtp.gmail.com",
//         Username: "softcodeitt@gmail.com",
//         Password: "soft@119",
//         To: 'softcodeitt@gmail.com',
//         From: "new",
//         Subject: "This is the subject",
//         Body: "And this is the body"
//     }).then(
//         message => alert(message)

//     );
// }


/*-------------------------------------------------------------------------------
	  Ajax Forms
-------------------------------------------------------------------------------*/
var contactform = function() {
    if ($('.scit-contact').length) {
		$('.scit-contact').each(function(){
			$(this).validate({
				errorClass: 'error wobble-error',
			    submitHandler: function(form){
		        	$.ajax({
			            type: "POST",
			            url:"contact.asp",
			            data: $(form).serialize(),
			            success: function() {
			            	$('#error').removeClass('show');
		                	$('#success').addClass('show');

		                	$('.form-values').val("");
		                },

		                error: function(){
		                	$('#success').removeClass('show');
			                $('#error').addClass('show');
			            }
			        });
			    }
			});
		});
	}
}

	